a:hover {
    text-decoration: none;
    cursor: pointer;
}

.main-title {
    font-weight: bold;
    margin-top: 15px;
    margin-left: 0px;
    margin-right: 15px;
    margin-bottom: 0px;
}

.main-title-indent {
    font-weight: bold;
    margin-top: 15px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 0px;
}

.title {
    font-weight: bold;
    margin-top: 15px;
    margin-left: 0px;
    margin-right: 15px;
    margin-bottom: 0px;
}

.title-indent {
    font-weight: bold;
    margin-top: 15px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 0px;
}

.paragraph {
    padding-top: 15px;
    padding-left: 0px;
    padding-right: 15px;
    padding-bottom: 0px;
}

.paragraph-indent {
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 0px;
}

.text {
    font-family: system-ui, sans-serif;
    line-height: 1.8;
}
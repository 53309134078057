.main-table-indent {
    display: table;
    padding: 0px;
    margin: 0px;
    margin-left: 15px;
    margin-top: 1em;
    /* height: 12em; */
}
.main-table {
    display: table;
    padding: 0px;
    margin: 0px;
    margin-left: 0px;
    margin-top: 1em;
    /* height: 12em; */
}
.main-row {
    text-align: center;
    display: table-row;
    padding: 0px;
    margin: 0px;
    width: 90%;
}

.main-cell {
    padding: 0px;
    margin: 0px;
    padding-left: 0px;
    margin-left: 0px;    
    vertical-align: top;
    text-align: left;
    margin-bottom: 0px;       
    display: table-cell;     
}

@media (max-width: 800px) {
    .main-cell {
        display: block;     
    }
}

.item-table {
    display: table;
    width: min-content;
    padding: 0px;
    margin: 0px;
    margin-top: -5px;
    width: 100%;
}

@media (max-width: 800px) {
    .item-table {
        margin-top: 25px;
    }
}

.item-row {
    text-align: center;
    display: table-row;
    padding: 0px;
    margin: 0px;
    width: 75%;    
}
.item-cell {
    padding: 5px;
    padding-top: 1px;
    padding-left: 8px;
    margin: 3px;
    display: table-cell;
    vertical-align: top;
    text-align: left;
}
.bullet-cell {
    padding: 5px;
    padding-top: 0px;
    margin: 3px;
    display: table-cell;
    vertical-align: top;
    text-align: left;
}
div.role-container {
    width: 15em;
    height: 4em;
    padding: 15px;
    font-size: 1.25em;
    margin-right: 25px;
    box-shadow: 10px 5px 5px gray;
}    

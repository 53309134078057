.menu-bar {
    padding: 0px !important;
    margin: 0px !important;
    border: 0px;
    border-top: 0.02em;
    border-bottom: 0.02em;
    border-style: solid;
    height: 3em;
    background-color: red;
}

.navigation-bar {
    padding: 0px !important;
    margin: 0px !important;
}

.menu-toggle {
    background-color: transparent;
}

.active-menu-link {
    padding: 0px !important;
    margin: 0px !important;
    margin-left: 0px !important;
    margin-top: 0.625em !important;
    /* margin-right: 0.9375em !important; */
    margin-right: 2em !important;
    margin-bottom: 0.625em !important;

    text-decoration: none;
    cursor: default !important;
    border: 0px;
    border-bottom: 0.1875em;
    border-style: solid;
}

.menu-link {
    padding: 0px !important;
    margin: 0px !important;
    margin-left: 0px !important;
    margin-top: 0.625em !important;
    /* margin-right: 0.9375em !important; */
    margin-right: 2em !important;
    margin-bottom: 0.625em !important;

    border: 0px;        
    border-bottom: 0.1875em;
    border-style: none;
    border-color: transparent;
}

.expanded-active-menu-link {
    margin: 0px;
    display: none !important;

    /* margin: 0px;
    padding: 0px;
    padding-left: 0px;
    padding-right: 0px;
    margin-right: 2em;     */
}

.expanded-menu-link {
    margin: 0px;
    padding: 0px;
    padding-left: 0px;
    padding-right: 0px;
    margin-right: 2em;
}

.language-bar {
    background-color: transparent;
}

span.language {
    background-color: transparent;
}

span.active-language {
    background-color: transparent;
    margin: 0%;
    margin-left: 0.3em;
    margin-right: 0.3em;
    padding: 0%;
    border-width: 0px;
    border-bottom: 0.1875em;
    border-style: solid;
    cursor: default !important;
    padding-bottom: 0.1875em;
}

button.language {
    margin: 0%;
    padding: 0%;
    margin-left: 0.3em;
    margin-right: 0.3em;
    background-color: transparent;
    border-width: 0px;
}

button.active-language {
    margin: 0%;
    margin-left: 0.3em;
    margin-right: 0.3em;
    padding: 0%;
    border-width: 0px;
    border-bottom: 0.1875em;
    border-style: solid;
    cursor: default !important;
}

a.language {
    margin: 0%;
    padding: 0%;
    margin-left: 0.3em;
    margin-right: 0.3em;
    background-color: transparent;
    border-width: 0px;
}

a.language:hover  {
    text-decoration: none;
    cursor: pointer !important;
}

a.active-language {
    margin: 0%;
    margin-left: 0.3em;
    margin-right: 0.3em;
    padding: 0%;
    border-width: 0px;
    border-bottom: 0.1875em;
    border-style: solid;
}

a.active-language:hover {
    text-decoration: none;
    cursor: pointer !important;
    /* cursor: pointer; */
}

a.nightmode {
    padding-right: 0.5em;
}

.switch-app {
    padding-right: 0.5em;
}
html {
    overflow-y: scroll;
}

h4 {
    margin-bottom: 0.75em !important;
}

.ext-paragraph {
    padding-top: 0em;
    padding-left: 0em;
    padding-right: 1em;
    padding-bottom: 0em;
}

.ext-paragraph-indent {
    padding-top: 0em;
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 0em;
}

.form-group {
    background-color: transparent;
}

.form-control {
    background-color: transparent;
}

.button {
    padding: 5px;
    padding-top: 0px;
    height: 40px;
    width: 40px;
    cursor: pointer;
    outline: none;
    border: none;
    border-radius: 5px;
    box-shadow: 0 2px black;
}

.button-disabled {
    padding: 5px;
    padding-top: 0px;
    height: 40px;
    width: 40px;
    outline: none;
    border: none;
    border-radius: 5px;
}

.button:active {
    box-shadow: 0 0px;
    transform: translateY(4px);
}

.button-daymode {
    box-shadow: 0 2px lightgray;
}

.button-nightmode {
    box-shadow: 0 2px black;
}
.button-disabled-daymode {
    /* box-shadow: 0 2px whitesmoke; */
}

.button-disabled-nightmode {
    /* box-shadow: 0 2px darkgray; */
}

.editor:-webkit-autofill:hover,
.editor:-webkit-autofill:focus,
.editor:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 2em whitesmoke inset !important;
}

.editor:focus {
    background-color: whitesmoke !important;
}

.editor {
    max-width: 50em;
}

.editor-nightmode:-webkit-autofill:hover,
.editor-nightmode:-webkit-autofill:focus,
.editor-nightmode:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 2em #aaacad inset !important;
}

.editor-nightmode:focus {
    background-color: #aaacad !important;
}

.editor-nightmode {
    max-width: 50em;
}

.tooltip-inner {
    background-color: whitesmoke !important;
    border-color: black !important;
    border-style: solid !important;
    border-width: 1px !important;
    border-radius: 5px !important;
    margin: 0px !important; padding: 3px !important;
    color: black !important;
}

.tooltip-inner > div {
    margin: 0px !important; padding: 0px !important;
    background-color: whitesmoke !important;
}
